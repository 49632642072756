/**
 * Sanity API configuration
 * Stores the configuration for the Sanity API client and adds fallbacks for environment variables
 */

// References to include in 'internal' links
export const PAGE_REFERENCES = [
	{ type: 'homePage' },
	{ type: 'contactPage' },
	{ type: 'productPages' },
	{ type: 'marketPages' },
	{ type: 'teams' },
	{ type: 'newsPage' },
	{ type: 'technologyPage' },
	{ type: 'impactPage' },
	{ type: 'companyPage' },
	{ type: 'privacyPage' },
	{ type: 'termsPage' },
	{ type: 'legalPages' },
]

export const SANITY_API_VERSION = process.env.NEXT_PUBLIC_SANITY_API_VERSION || '2023-08-08'

export const SANITY_DATASET = assertValue(
	process.env.NEXT_PUBLIC_SANITY_DATASET,
	'Missing environment variable: NEXT_PUBLIC_SANITY_DATASET'
)

export const SANITY_PROJECT_ID = assertValue(
	process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
	'Missing environment variable: NEXT_PUBLIC_SANITY_PROJECT_ID'
)

export const PROJECT_TITLE =
	process.env.NEXT_PUBLIC_SANITY_PROJECT_TITLE ||
	'Sanity Studio - "title" not defined in frontend/.env.local'

export const STUDIO_PREVIEW_DOMAIN = assertValue(
	process.env.NEXT_PUBLIC_SANITY_STUDIO_PREVIEW_DOMAIN,
	'Missing environment variable: NEXT_PUBLIC_SANITY_STUDIO_PREVIEW_DOMAIN'
)

export const STUDIO_BASE_PATH = '/studio'

export const readToken = process.env.SANITY_API_READ_TOKEN!

// This is the document id used for the preview secret that's stored in the dataset.
// The secret protects against unauthorized access to draft content and have a lifetime of 60 minutes, to protect against bruteforcing.
export const PREVIEW_SECRET_ID: `${string}.${string}` = 'preview.secret'

// See the app/api/revalidate/route.ts for how this is used
export const REVALIDATE_SECRET = process.env.SANITY_REVALIDATE_SECRET

export const LANGUAGES = [{ id: 'en', title: 'English', icon: '🇬🇧', previewUrl: '' }]

function assertValue<T>(v: T | undefined, errorMessage: string): T {
	if (v === undefined) {
		throw new Error(errorMessage)
	}

	return v
}
